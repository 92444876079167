







































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab4Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: '$0.10\\,\\text{g}$', value: '0.10'},
        {text: '$0.30\\,\\text{g}$', value: '0.30'},
        {text: '$0.60\\,\\text{g}$', value: '0.60'},
        {text: '$2.00\\,\\text{g}$', value: '2.00'},
        {text: '$3.00\\,\\text{g}$', value: '3.00'},
      ],
    };
  },
};
